<template>
  <div class="basic-details">
    <h5 class="mb-4">{{ $t("basic-details") }}</h5>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
      :shouldCheckValidity="validate"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
import { mapState } from "vuex";

// Fixtures
import InteriorAndPaperTypes from "@/fixtures/interior-and-paper-types";
import BookPageSizes from "@/fixtures/book-page-sizes";
import BookTypes from "@/fixtures/book-types";
import CoverFinishes from "@/fixtures/cover-finishes";

export default {
  name: "basic-details",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    },
    validate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("ancillaries", ["languages"]),
    elements() {
      return [
        {
          key: "book_type",
          label: "book-type",
          type: "select",
          attrs: {
            options: BookTypes.map(t => ({ text: this.$t(t), value: t }))
          }
        },
        {
          key: "title",
          label: "book-title",
          type: "string",
          validation: {
            required: true
          }
        },
        {
          key: "subtitle",
          label: "book-subtitle",
          type: "string"
        },
        {
          key: "sales_description",
          label: "sales-description",
          type: "html",
          attrs: {
            editorToolbar: ["bold", "italic", "underline"]
          }
        },
        {
          key: "is_part_of_series",
          label: "is-book-part-of-series",
          type: "switch"
        },
        {
          showIf: ["is_part_of_series"],
          key: "series_name",
          label: "series-name",
          type: "string"
        },
        {
          showIf: ["is_part_of_series"],
          key: "series_number",
          label: "series-number",
          type: "number"
        },
        {
          key: "use_amazon_expanded_distribution",
          label: "use-amazon-expanded-distribution",
          type: "switch",
          showIf: [["book_type", "print"]]
        },
        {
          key: "primary_language_id",
          label: "primary-language",
          type: "select",
          attrs: {
            options: [
              { text: this.$t("select-primary-language"), value: null }
            ].concat(
              this.languages.map(l => ({
                text: l.name,
                value: l.id
              }))
            )
          }
        },
        {
          key: "page_size",
          label: "book-page-size",
          type: "select",
          attrs: {
            options: [
              { text: this.$t("select-book-page-size"), value: null }
            ].concat(
              BookPageSizes.map(s => ({
                text: this.$t("inches", { inches: s.replace(/-/g, " x ") }),
                value: s
              }))
            )
          },
          showIf: [["book_type", "print"]]
        },
        {
          key: "cover_finish",
          label: "cover-finish",
          type: "select",
          attrs: {
            options: [
              { text: this.$t("select-cover-finish"), value: null }
            ].concat(
              CoverFinishes.map(s => ({
                text: this.$t(s),
                value: s
              }))
            )
          },
          showIf: [["book_type", "print"]]
        },
        {
          key: "interior_and_paper_type",
          label: "interior-and-paper-type",
          type: "select",
          attrs: {
            options: [
              { text: this.$t("select-interior-and-paper-type"), value: null }
            ].concat(
              InteriorAndPaperTypes.map(s => ({
                text: this.$t(s),
                value: s
              }))
            )
          },
          showIf: [["book_type", "print"]]
        }
      ];
    }
  }
};
</script>

<style></style>
